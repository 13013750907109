import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import axiosInstance from "../utils/axios";

export default function useAuthAxios() {
    const authHeader = useAuthHeader();
    const axios = axiosInstance;

    axios.interceptors.request.use(
        config => {
            config.headers.Authorization = authHeader;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    return axios;
}