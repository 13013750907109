import { Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import Input from "../common/form/Input";
import SelectField from "../common/form/Select";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createStrategy, updateStrategy } from "../../services/strategies";
import useAuthAxios from "../../hooks/useAuthAxios";
import { useNavigate } from "react-router-dom";


interface StrategyDetailProps {
    strategy: Strategy;
    isNew?: boolean;
}

const StrategyDetail: React.FC<StrategyDetailProps> = ({ strategy, isNew }) => {
    const axiosAuth = useAuthAxios();
    const navigate = useNavigate();
    return (
        <>
            <Formik
                initialValues={
                    isNew
                        ? {
                            strategy_name: "",
                            coin_pairs: "",
                            stop_loss_percentage: 0,
                            is_active: true,
                            is_dry_run: false,
                        }
                        : strategy
                }
                onSubmit={async (values, {
                    setSubmitting,
                    setErrors,
                    setValues,
                }) => {
                    try{
                        if(isNew){
                            const response = await createStrategy(axiosAuth, {
                                strategy_name: values.strategy_name,
                                coin_pairs: values.coin_pairs,
                                stop_loss_percentage: values.stop_loss_percentage,
                                is_active: values.is_active,
                                is_dry_run: values.is_dry_run,
                            });
                            
                            if(response){
                                toast.success("Strategy created successfully");
                                navigate('/strategies' + response.id );
                            }
                        }else{
                            const response = await updateStrategy(axiosAuth, values);
                            if(response){
                                toast.success("Strategy updated successfully");
                            }
                        }

                        setSubmitting(false);
                    }catch(e){
                        console.log(e);
                        setSubmitting(false);
                        toast.error("Something went wrong");
                    }
                }}
                validationSchema={Yup.object({
                    strategy_name: Yup.string().required("Required"),
                    coin_pairs: Yup.string().required("Required"),
                    stop_loss_percentage: Yup.number().required("Required"),
                    is_active: Yup.boolean().required("Required"),
                    is_dry_run: Yup.boolean().required("Required"),
                })}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            width: "50%",
                        }}
                    >
                        <Input name="strategy_name" label="Name" />
                        <Input name="coin_pairs" label="Coin Pairs" />
                        <Input name="stop_loss_percentage" label="Stop Loss Percentage" />
                        <SelectField name="is_active" label="Is Active" options={[
                            { label: "Yes", value: "true" },
                            { label: "No", value: "false" },
                        ]} />
                        <SelectField name="is_dry_run" label="Is Dry Run" options={[
                            { label: "Yes", value: "true" },
                            { label: "No", value: "false" },
                        ]} />

                        <Button
                            colorScheme="green"
                            isLoading={isSubmitting}
                            onClick={handleSubmit as any}
                        >Submit</Button>
                    </Form>
                )}

            </Formik>
        </>
    )
};

export default StrategyDetail;