import { Axios } from "axios";

const ROOT_URL = '/admin/strategies/';

export const getAllStrategies = async (axios: Axios) => {
    try {
        const response = await axios.get(ROOT_URL);
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getStrategyById = async (axios: Axios, id: number) => {
    try {
        const response = await axios.get(ROOT_URL + id + '/');
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const addAccountToStrategy = async (axios: Axios, strategyId: number, accountIds:number[]) => {
    try {
        const response = await axios.post(ROOT_URL + strategyId + '/', {
            account_ids: accountIds
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};


export const createStrategy = async (axios: Axios, strategy: any) => {
    try {
        const response = await axios.post(ROOT_URL, strategy);
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const updateStrategy = async (axios: Axios, strategy: any) => {
    try {
        const response = await axios.put(ROOT_URL + strategy.id + '/', strategy);
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const updateStrategyAccountDetails = async (axios: Axios, strategyId: number, accounts:StrategyAccount[]) => {
    try {
        const response = await axios.patch(ROOT_URL + strategyId + '/', {
            accounts: accounts
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}