export const EXCHANGE_NAMES = [
    {
        "label": "Binance",
        "value": "binance"
    },
    {
        "label": "Coinbase",
        "value": "coinbase"
    },
    {
        "label": "Kraken",
        "value": "kraken"
    }
]