import axiosInstance from '../utils/axios';

export const loginAdmin = async (email: string, password: string, signIn: any) => {
    try {
        const response = await axiosInstance.post('/auth/login/', {email, password});
        if (response.status === 200) {
            signIn({
                auth: {
                    token: response.data.access,
                    type: 'Bearer',
                    refresh: response.data.refresh,
                },
                refresh: response.data.refresh,
                //Expires in 30 minutes
                expiresIn: 1800,
                userState: {
                    email,
                    token: response.data.access,
                },
            })
        }

        return true;
    } catch (e) {
        console.log(e)
        return false;
    }
}