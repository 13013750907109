import { AddIcon } from "@chakra-ui/icons";
import {
    Box,
    BoxProps,
    Button, CircularProgress,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    IconButton,
    Link,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, ReactText } from 'react';
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { FiMenu, } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import useAuthAxios from "../../hooks/useAuthAxios";

interface LinkItemProps {
    name: string;
    href?: string;
}


export default function Sidebar({ children }: { children: ReactNode }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const axiosAuth = useAuthAxios();

    const isFetching = false


    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
                isLoading={isFetching}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent
                        onClose={onClose}
                        isLoading={isFetching}
                    />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
    isLoading: boolean;
}

const SidebarContent = ({ onClose, isLoading, ...rest }: SidebarProps) => {
    const navigate = useNavigate();
    const signOut = useSignOut()

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            overflowX="hidden"
            overflowY="auto"
            zIndex={1}
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    Teak Finance
                </Text>

                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>

            <Box>
                <NavItem href="/accounts">Accounts</NavItem>
                <NavItem href="/strategies">Strategies</NavItem>
                <NavItem href="/trades">Trades</NavItem>
            </Box>


            <Flex
                alignItems={'center'}
                direction={'column'}
                px="4"
                justifyContent={'center'}
                my="12"
            >
                <Button onClick={() => {
                    signOut()
                    navigate('/')
                    window.location.reload()
                }}>Sign Out</Button>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    children: ReactText;
    href: string;
}

const NavItem = ({ children, href, ...rest }: NavItemProps) => {
    return (
        <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'green.700',
                    color: 'white',
                }}
                {...rest}>
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
                Teak Finance
            </Text>
        </Flex>
    );
};