import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuthAxios from "../hooks/useAuthAxios";
import { getStrategyById } from "../services/strategies";
import { useQuery } from "react-query";
import Loader from "../components/common/Loader";
import StrategyDetail from "../components/strategyDetails/StrategyDetail";
import ConnectedAccounts from "../components/strategyDetails/ConnectedAccounts";
import StrategyTradesList from "../components/strategyDetails/StrategyTradesList";
import PageHeader from "../components/common/PageHeader";

const StrategyDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const isNew = id == 'create';
    const axiosAuth = useAuthAxios();

    const { data, isFetched, isLoading, isFetching, isError, error } = useQuery<Strategy>('strategy_' + id, async () => {
        if (isNew) {
            return {
                trades: [],
                accounts: [],
            }
        }
        const response = await getStrategyById(axiosAuth, parseInt(id));
        console.log(response);
        return response;
    }, {

    })

    if (isLoading || isFetching) {
        return <Loader />
    }

    if (!data || isError) {
        return <div>
            Something went wrong
            {/* @ts-ignore */}
            {error && <div>{error}</div>}
        </div>
    }


    return (
        <>
            <PageHeader title="Strategy Edit" />
            
            <Box p={4}>
                <Tabs>
                    <TabList>
                        <Tab>Strategy Details</Tab>
                        <Tab>Connected Accounts</Tab>
                        <Tab>Trades</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <StrategyDetail
                                isNew={isNew}
                                strategy={data}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ConnectedAccounts
                                accounts={data.accounts}
                                strategyId={data.id}
                            />
                        </TabPanel>
                        <TabPanel>
                            <StrategyTradesList
                                trades={data.trades}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    );
};

export default StrategyDetailPage;