import { Axios } from "axios";

const ROOT_URL = '/admin/trades/';

export const getAllTrades = async (axios: Axios) => {
    try {
        const response = await axios.get(ROOT_URL);
        return response.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};