export const HomePage = () => {
    


    return (
        <div>
            <p>Home</p>

        </div>
    );
}