import React from "react";
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";


interface StrategyTradesListProps {
    trades: Trade[];
}

const StrategyTradesList: React.FC<StrategyTradesListProps> = ({
    trades,
}) => {
    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                    <Text fontSize="xl" fontWeight="bold">
                        Transactions List
                    </Text>
                </Box>
                <Table variant="striped" p={'1rem'}>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Side</Th>
                            <Th>Base Asset</Th>
                            <Th>Quote Asset</Th>
                            <Th>Quantity</Th>
                            <Th>Price</Th>
                            <Th>Amount</Th>
                            <Th>Order Type</Th>
                            <Th>Status</Th>

                            <Th>Dry Run</Th>
                            <Th>
                                Actions
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        {
                            trades.map((trade) => (
                                <Tr key={trade.id}>
                                    <Td>{trade.id}</Td>
                                    <Td>{trade.side}</Td>
                                    <Td>{trade.base_asset}</Td>
                                    <Td>{trade.quote_asset}</Td>
                                    <Td>{trade.quantity}</Td>
                                    <Td>{trade.price}</Td>
                                    <Td>{trade.amount}</Td>
                                    <Td>{trade.order_type}</Td>
                                    <Td>{trade.status}</Td>
                                    <Td>{trade.is_dry_run ? 'Yes' : 'No'}</Td>

                                    <Td>
                                        <TradeDetailModal
                                            trade={trade}
                                        />
                                    </Td>
                                </Tr>
                            ))
                        }

                    </Tbody>
                </Table>
            </Box>
        </>
    )
};

const TradeDetailModal: React.FC<{ trade: Trade }> = ({ trade }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    return (
        <>
            <Button
                onClick={onOpen}
                colorScheme="blue"
            >
                View Details
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Trade Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                            <Box>
                                <Text>ID: {trade.id}</Text>
                                <Text>Side: {trade.side}</Text>
                                <Text>Base Asset: {trade.base_asset}</Text>
                                <Text>Quote Asset: {trade.quote_asset}</Text>
                                <Text>Quantity: {trade.quantity}</Text>
                                <Text>Price: {trade.price}</Text>
                                <Text>Amount: {trade.amount}</Text>
                                <Text>Order Type: {trade.order_type}</Text>
                                <Text>Status: {trade.status}</Text>
                                <Text>Dry Run: {trade.is_dry_run ? 'Yes' : 'No'}</Text>
                                <Text>Exchange Fee {trade.exchange_fee}</Text>
                                <Text>
                                    Stop Loss Hit {trade.stop_loss_hit ? 'Yes' : 'No'}
                                </Text>
                                <Text>Order ID: {trade.order_id}</Text>
                                <Text>Order Response: {trade.order_response}</Text>
                                <Text>Created At: {
                                    new Date(trade.created_at).toLocaleString()
                                }</Text>
                                <Text>Updated At: {
                                    new Date(trade.updated_at).toLocaleString()
                                }</Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default StrategyTradesList;