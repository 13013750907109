import React from "react";
import CustomTable from "../common/CustomTable";
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { FieldArray, Form, Formik } from "formik";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Input from "../common/form/Input";
import SelectField from "../common/form/Select";
import { useMutation, useQuery } from "react-query";
import { getAllAccounts } from "../../services/accounts";
import useAuthAxios from "../../hooks/useAuthAxios";
import { toast } from "react-toastify";
import { addAccountToStrategy, updateStrategyAccountDetails } from "../../services/strategies";


interface ConnectedAccountsProps {
    accounts: StrategyAccount[];
    strategyId: number;
}

const ConnectedAccounts: React.FC<ConnectedAccountsProps> = ({
    accounts,
    strategyId
}) => {
    const axiosAuth = useAuthAxios();
    const { mutate, isLoading } = useMutation(async ({
        data,
        setSubmitting
    }: {
        data: StrategyAccount[];
        setSubmitting: any;
    }) => {
        await updateStrategyAccountDetails(axiosAuth, strategyId, data);
        setSubmitting(false);
    }, {
        onSuccess: () => {
            toast.success("Accounts updated successfully");
        },
        onError: () => {
            toast.error("Something went wrong");
        }
    })
    return (
        <>
            <Formik
                initialValues={{
                    accounts: accounts,
                }}
                onSubmit={(values, {
                    setSubmitting
                }) => {
                    console.log(values);
                    return mutate({
                        data: values.accounts,
                        setSubmitting
                    });
                }}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <Box>

                            <FieldArray name="accounts">
                                {({ push, remove, name }) => {
                                    return (
                                        <>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                                                <Text fontSize="xl" fontWeight="bold">
                                                    Connected Accounts
                                                </Text>
                                                <Box
                                                    display="flex"
                                                    gap={2}
                                                >
                                                    <AddAccountModal
                                                        strategyId={strategyId}
                                                    />
                                                    <Button
                                                        type="submit"
                                                        colorScheme="blue"
                                                        isLoading={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Table variant="striped" p={'1rem'}>
                                                <Thead>
                                                    <Tr>
                                                        <Th>ID</Th>
                                                        <Th>Trading Currency</Th>
                                                        <Th>Stop Loss Percentage</Th>
                                                        <Th>Account Name</Th>
                                                        <Th>Active Status</Th>
                                                        <Th>Dry Run</Th>
                                                        <Th>
                                                            Actions
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {values.accounts.map((account, index) => (
                                                        <Tr key={index}>
                                                            <Td>{account.id}</Td>
                                                            <Td>
                                                                <Input
                                                                    name={`accounts.${index}.trading_currency`}
                                                                />
                                                            </Td>
                                                            <Td>
                                                                <Input
                                                                    name={`accounts.${index}.stop_loss_percentage`}
                                                                    type="number"
                                                                />
                                                            </Td>
                                                            <Td>
                                                                {
                                                                    account.account?.name
                                                                }

                                                            </Td>
                                                            <Td>
                                                                <SelectField
                                                                    name={`accounts.${index}.is_active`}
                                                                    options={[
                                                                        { label: "Yes", value: "true" },
                                                                        { label: "No", value: "false" },
                                                                    ]}
                                                                />
                                                            </Td>
                                                            <Td>
                                                                <SelectField
                                                                    name={`accounts.${index}.is_dry_run`}
                                                                    options={[
                                                                        { label: "Yes", value: "true" },
                                                                        { label: "No", value: "false" },
                                                                    ]}
                                                                />
                                                            </Td>
                                                            <Td
                                                                display="flex"
                                                                gap={2}
                                                            >
                                                                <Button colorScheme="red"
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>

                                        </>
                                    )
                                }}
                            </FieldArray>

                        </Box>
                    </Form>
                )}
            </Formik>

        </>
    )
};

const AddAccountModal: React.FC<{
    strategyId: number;
}> = ({ strategyId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const axiosAuth = useAuthAxios();

    const { data: accountsList, isFetching: isFetchingAccountsList } = useQuery('accounts', async () => {
        const response = await getAllAccounts(axiosAuth);
        return response;
    }, {});

    //@ts-ignore
    const { mutate } = useMutation(async (ids: number[]) => {
        const response = await addAccountToStrategy(axiosAuth, strategyId, ids);
        console.log(response);

        if (response && response.failed_accounts && response.failed_accounts.length > 0) {
            toast.error(
                `
                Failed to add accounts:
                ${response.failed_accounts.map((account: any) => ` ${account.id} - ${account.message}`)}
                `
            );
        }

        if (!response) {
            toast.error("Something went wrong");
        }

        if (response && response.successful_accounts && response.successful_accounts.length > 0) {
            toast.success(
                `
                Successfully added accounts:
                ${response.successful_accounts.map((account: any) => ` ${account.id} - ${account.message}`)}
                `
            );
        }

        return response;
    }, {
        onSuccess: () => {

        },
        onError: (error) => {

        }
    });

    return (
        <>
            <Button
                colorScheme="green"
                onClick={onOpen}
            >
                Add Account
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <Formik
                    initialValues={{
                        accounts: [],
                        currentAccount: null
                    }}
                    onSubmit={(values, {
                        setSubmitting,
                    }) => {
                        const accountIds = values.accounts.map((account: Account) => account.id);
                        return mutate(accountIds);
                    }}
                >
                    {({ values, isSubmitting, setValues }) => (
                        <Form>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>
                                    Add Account
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={4}
                                        mb={8}
                                    >
                                        <Text>
                                            Selected Accounts
                                        </Text>
                                        <Flex
                                            gap={2}
                                        >
                                            {
                                                values.accounts.map((account, index) => {
                                                    return (
                                                        <Box key={index}
                                                            style={{
                                                                padding: "1rem",
                                                                border: '1px solid #ccc',
                                                                borderRadius: '8px',
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Text>
                                                                {account.name}
                                                            </Text>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Flex>
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={2}
                                    >
                                        <Text>
                                            Select Account
                                        </Text>

                                        <Box
                                            display="flex"
                                            gap={2}
                                        >
                                            <SelectField
                                                name="currentAccount"
                                                isLoading={isFetchingAccountsList}
                                                options={accountsList && accountsList?.map((account: Account) => {
                                                    return {
                                                        label: account.name,
                                                        value: account.id.toString()
                                                    }
                                                })}
                                            />
                                            <Button
                                                colorScheme="blue"
                                                onClick={() => {
                                                    if (values.currentAccount) {
                                                        const account = accountsList?.find((account: Account) => account.id === parseInt(values.currentAccount));
                                                        console.log("Account", account);
                                                        setValues({
                                                            ...values,
                                                            accounts: [...values.accounts, { ...account }]
                                                        })
                                                    }
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </Box>
                                    </Box>


                                </ModalBody>
                                <ModalFooter
                                    display="flex"
                                    gap={2}
                                >
                                    <Button
                                        colorScheme="red"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        type="submit"
                                        isLoading={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>

        </>
    )
}

export default ConnectedAccounts;