import { FormControl, FormErrorMessage, Input as ChakraInput, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: string;
};

const Input: React.FC<InputProps> = ({ label, size: _, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <FormControl isInvalid={meta.touched && meta.error !== undefined}>
            {label && <FormLabel htmlFor={props.name}>{label}</FormLabel>}
            <ChakraInput {...field} {...props} id={props.name} />
            {meta.touched && meta.error && (
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            )}
        </FormControl>
    );
};

export default Input;