import createRefresh from "react-auth-kit/createRefresh"
import axiosInstance from "../utils/axios"


const refresh = createRefresh({
    interval: 100 ,
    //@ts-ignore
    refreshApiCallback: async (param) => {
      try {
        const response = await axiosInstance.post("auth/", {
          refresh: param.refreshToken,
          ...param
        }, {
          headers: {'Authorization': `Bearer ${param.authToken}`}
        })
        console.log("Refreshing", response)
        return {
          isSuccess: true,
          newAuthToken: response.data.access,
          newAuthTokenExpireIn: 100,
          newRefreshTokenExpiresIn: 600
        }
      }
      catch(error){
        console.error(error)
        return {
          isSuccess: false
        } 
      }
    },
  });

export default refresh;