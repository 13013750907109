import { Box, Heading } from "@chakra-ui/react";

interface PageHeaderProps {
    title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
    return (
        <Box py={4} borderBottom="1px" borderColor="gray.200" marginBottom={"1rem"}>
            <Heading as="h1" size="lg">
                {title}
            </Heading>
        </Box>
    );
};

export default PageHeader;