import { useQuery } from "react-query";
import useAuthAxios from "../hooks/useAuthAxios";
import { getAllTrades } from "../services/trades";
import Loader from "../components/common/Loader";
import PageHeader from "../components/common/PageHeader";
import TradeList from "../components/trades/TradesList";

const TradesPage = () => {
    const axiosAuth = useAuthAxios();

    const { data,isLoading, isFetching , isError, error} = useQuery('trades', async () => {
        const response = await getAllTrades(axiosAuth);
        console.log(response);
        return response;
    }, {})

    if (isLoading || isFetching) {
        return <Loader />
    }

    if(!data || isError) {
        return <div>
            Something went wrong
            {/* @ts-ignore */}
            {error && <div>{error}</div>}

        </div>
    }

    return (<>
        <PageHeader title="Trades" />
        <TradeList trades={data} />
    </>)
};

export default TradesPage;