import { useEffect } from "react";
import useAuthAxios from "../hooks/useAuthAxios";
import { useQuery } from "react-query";
import { getAllAccounts } from "../services/accounts";
import CreateEditAccountModal from "../components/accounts/CreateEditAccountModal";
import AccountsList from "../components/accounts/AccountsList";
import Loader from "../components/common/Loader";
import PageHeader from "../components/common/PageHeader";

const AccountsPage = () => {
    const axiosAuth = useAuthAxios();

    const { data, isFetched, isLoading, isFetching, isError, error } = useQuery('users', async () => {
        const response = await getAllAccounts(axiosAuth);
        console.log(response);
        return response;
    }, {})

    if (isLoading || isFetching) {
        return <Loader />
    }

    if(!data || isError) {
        return <div>
            Something went wrong
            {/* @ts-ignore */}
            {error && <div>{error}</div>}

        </div>
    }

    return (<>
        <PageHeader title="Accounts" />
        <AccountsList accounts={data} />
    </>)
};

export default AccountsPage;