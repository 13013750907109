import { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, Text } from '@chakra-ui/react';

interface ListStrategiesModalProps {
    strategies: Strategy[];
    account: Account;
    children?: React.ReactNode;
    colorScheme?: string;
    size?: string;
}


const ListStrategiesModal: React.FC<ListStrategiesModalProps> = ({ strategies, account, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

  
    return (
        <>
        {
                props.children ? (
                    <Button onClick={onOpen}
                        {...props}
                    >
                        {props.children}
                    </Button>
                ) : (
                    <Button onClick={onOpen}>
                        Open Modal
                    </Button>
                )
            }
        <Modal isOpen={isOpen} onClose={onClose}
            size={'xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>List of Strategies for Account: {account.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        strategies && strategies.length > 0 ? (
                            <Table variant="striped" p={'1rem'}>
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Name</Th>
                                        <Th>Coin Pairs</Th>
                                        <Th>Active Status</Th>
                                        <Th>Stop Loss Percentage</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {strategies.map((strategy) => (
                                        <Tr key={strategy.id}>
                                            <Td>{strategy.id}</Td>
                                            <Td>{strategy.strategy_name}</Td>
                                            <Td>
                                                {strategy.coin_pairs && strategy.coin_pairs.split(',').map((coin, index) => {
                                                    // @ts-ignore
                                                    return <Text key={index}>{coin}</Text>
                                                })}
                                            </Td>
                                            <Td>{strategy.is_active ? 'Active' : 'Inactive'}</Td>
                                            <Td>{strategy.stop_loss_percentage}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text>No Strategies found for this account</Text>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    );
};

export default ListStrategiesModal;