import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import CreateEditAccountModal from './CreateEditAccountModal';
import ListStrategiesModal from './ListStrategiesModal';


interface AccountsListProps {
    accounts: Account[];
}

const AccountsList: React.FC<AccountsListProps> = ({ accounts }) => {
    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold">Accounts List</Text>
                <CreateEditAccountModal
                    account={null}
                    isNew={true}
                    colorScheme="green"
                >
                    Create Account
                </CreateEditAccountModal>
            </Box>
            <Table variant="striped" p={'1rem'}>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>#Strategies</Th>
                        <Th>Active Status</Th>
                        <Th>Last Modified</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {accounts.map((account) => (
                        <Tr key={account.id}>
                            <Td>{account.id}</Td>
                            <Td>{account.name}</Td>
                            <Td>{account.email}</Td>
                            <Td>{account.num_strategies}</Td>
                            <Td>{account.is_active ? 'Active' : 'Inactive'}</Td>
                            <Td>{new Date(account.updated_at).toLocaleDateString()
                                }</Td>
                            <Td
                                display='flex'
                                gap={2}
                            >
                                <CreateEditAccountModal
                                    account={account}
                                    isNew={false}
                                    colorScheme='blue'
                                    size='sm'
                                >
                                    Edit
                                </CreateEditAccountModal>
                                <ListStrategiesModal
                                    account={account}
                                    strategies={account.strategies}
                                    colorScheme='purple'
                                    size='sm'
                                >
                                    View Strategies
                                </ListStrategiesModal>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default AccountsList;