import React from 'react';
import { useField } from 'formik';
import { Select, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

interface SelectFieldProps {
    name: string;
    label?: string;
    options: { value: string; label: string }[];
    [key: string]: any;
}

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options, ...props }) => {
    const [field, meta] = useField(name);

    return (
        <FormControl isInvalid={!!meta.touched && !!meta.error}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Select {...field} id={name} placeholder="Select an option" 
                {...props}
            >
                {options && options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};

export default SelectField;