import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Form, Formik } from "formik";
import Input from "../common/form/Input";
import SelectField from "../common/form/Select";
import { EXCHANGE_NAMES } from "../../lib/constants";
import RadioField from "../common/form/Radio";
import * as Yup from 'yup';
import { useMutation } from "react-query";
import { createAccount, updateAccount } from "../../services/accounts";
import { toast } from "react-toastify";
import { queryClient } from "../../App";


interface CreateEditAccountModalProps {
    account: Account;
    isNew: boolean;
    children?: React.ReactNode;
    colorScheme?: string;
    size?: string;
}

const CreateEditAccountModal: React.FC<CreateEditAccountModalProps> = ({ account, isNew, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const axiosAuth = useAuthAxios();

    const {mutate, isLoading} = useMutation((data: any) => {
        if (isNew) {
            return createAccount(axiosAuth, data);
        } else {
            return updateAccount(axiosAuth, data);
        }
        
    }, {
        onSuccess: () => {
            toast.success(
                `Account ${isNew ? 'created' : 'updated'} successfully`
            );
            //Refetch the data to update the UI
            queryClient.invalidateQueries('users');
        },
        onError: (error: any) => {
            toast.error(error.response.data.message);
        },
    });


    return (
        <>
            {
                props.children ? (
                    <Button onClick={onOpen}
                        {...props}
                    >
                        {props.children}
                    </Button>
                ) : (
                    <Button onClick={onOpen}>
                        Open Modal
                    </Button>
                )
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <Formik
                    initialValues={{
                        name: account?.name || '',
                        email: account?.email || '',
                        is_active: account?.is_active || "false",
                        api_key: account?.api_key || '',
                        secret_key: account?.secret_key || '',
                        exchange: account?.exchange || '',
                        created_at: account?.created_at || '',
                        updated_at: account?.updated_at || '',
                    }}
                    onSubmit={async (values) => {
                        console.log(values);
                        mutate({
                            ...values,
                            id: account?.id,
                        });
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Name is required'),
                        email: Yup.string().email('Invalid email').required('Email is required'),
                        api_key: Yup.string().required('API Key is required'),
                        secret_key: Yup.string().required('Secret Key is required'),
                        exchange: Yup.string().required('Exchange is required'),
                        is_active: Yup.boolean().required('Active status is required'),
                    })}
                >
                    {({ handleSubmit, isSubmitting, values }) => (
                        <ModalContent>
                            <ModalHeader>
                                {isNew ? 'Create Account' : 'Edit Account'}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>

                                <Form
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '1rem',
                                    }}
                                >
                                    <Input
                                        name='name'
                                        label='Name'
                                    />
                                    <Input
                                        name='email'
                                        label='Email'
                                    />
                                    <Input
                                        name='api_key'
                                        label='API Key'
                                    />
                                    <Input
                                        name='secret_key'
                                        label='Secret Key'
                                    />

                                    <SelectField
                                        name='exchange'
                                        label='Exchange'
                                        options={EXCHANGE_NAMES}
                                    />

                                    <SelectField
                                        name='is_active'
                                        label='Active'
                                        options={[
                                            { value: 'true', label: 'Yes' },
                                            { value: 'false', label: 'No' },
                                        ]}
                                    />

                                    {
                                        values?.created_at && (
                                            <Text
                                                fontSize='sm'
                                                color='gray.500'
                                            >
                                                Created At: {
                                                    new Date(values.created_at).toLocaleString()
                                                }
                                            </Text>
                                        )
                                    }

                                    {
                                        values?.updated_at && (
                                            <Text
                                                fontSize='sm'
                                                color='gray.500'
                                            >
                                                Last Modified At: {
                                                    new Date(values.updated_at).toLocaleString()
                                                }
                                            </Text>
                                        )
                                    }

                                </Form>

                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                    Close
                                </Button>
                                <Button
                                    colorScheme='green'
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    isLoading={isSubmitting || isLoading}
                                >
                                    {
                                        isNew ? 'Create Account' : 'Update Account'
                                    }
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default CreateEditAccountModal;