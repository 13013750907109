import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { queryClient } from '../../App';


interface TradeListProps {
    trades: Trade[];
}

const TradeList: React.FC<TradeListProps> = ({ trades }) => {
    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold">Trades List</Text>
                <Button colorScheme="green" onClick={
                    () => {
                       queryClient.invalidateQueries('trades')
                    }
                }>Refresh</Button>
            </Box>
            <Table variant="striped" p={'1rem'}>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Quote Asset</Th>
                        <Th>Base Asset</Th>
                        <Th>Side</Th>
                        <Th>Is Open</Th>
                        <Th>Quantity</Th>
                        <Th>Price</Th>
                        <Th>Last Modified</Th>
                        <Th>Account Name</Th>
                        <Th>Strategy Name</Th>
                        <Th>Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {trades.map((trade) => (
                        <Tr key={trade.id}>
                            <Td>{trade.id}</Td>
                            <Td>{trade.quote_asset}</Td>
                            <Td>{trade.base_asset}</Td>
                            <Td
                                color={trade.side === 'BUY' ? 'green.500' : 'red.500'}
                            >{trade.side}</Td>
                            <Td>{trade.is_open ? 'Open' : 'Closed'}</Td>
                            <Td>{trade.quantity}</Td>
                            <Td>{trade.price}</Td>
                            <Td>{new Date(trade.updated_at).toLocaleDateString()
                                }</Td>
                            
                            <Td>{trade.account.name}</Td>
                            <Td>{trade.strategy.strategy_name}</Td>
                            <Td
                                color={trade.status === 'SUCCESS' ? 'green.500' : 'red.500'}
                            >{trade.status}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default TradeList;