import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import AuthProvider from 'react-auth-kit';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from "./components/Layout";
import { Error404Page } from "./pages/404";
import AccountsPage from "./pages/Accounts";
import { HomePage } from "./pages/Home";
import LoginPage from "./pages/Login";
import StrategiesPage from "./pages/Strategies";
import StrategyDetailPage from './pages/StrategyDetail';
import TradesPage from "./pages/Trades";
import authStore from "./store/auth";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        }
    }
});

function App() {
    return (
        <div className="App">
            <AuthProvider store={authStore}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={'/login'} element={<LoginPage/>}/>
                            <Route element={<AuthOutlet fallbackPath={"/login"}/>}>
                                <Route element={<Layout/>}>
                                    <Route path={'/'} element={<HomePage/>}/>
                                    <Route path='/accounts' element={<AccountsPage/>}/>
                                    <Route path='/trades' element={<TradesPage/>}/>
                                    <Route path='/strategies' element={<StrategiesPage/>}/>
                                    <Route path='strategies/:id/*' element={<StrategyDetailPage/>}/>

                                    <Route path={'*'} element={<Error404Page/>}/>
                                </Route>
                            </Route>
                            <Route path={'*'} element={<Error404Page/>}/>
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
                <ToastContainer/>
            </AuthProvider>
        </div>
    );
}

export default App;
