import { Axios } from "axios";

const ROOT_URL = '/admin/accounts/';

export const getAllAccounts = async (axios:Axios) => {
    try {
        const response = await axios.get(ROOT_URL);
        return response.data;
    } catch (e) {
        console.log(e)
        return false;
    }
};

export const createAccount = async (axios:Axios, data: Account) => {
    try {
        const response = await axios.post(ROOT_URL, data);
        return response.data;
    } catch (e) {
        console.log(e)
        return false;
    }
};

export const updateAccount = async (axios:Axios, data: Account) => {
    try {
        const response = await axios.put(ROOT_URL, data);
        console.log(response)
        return response.data;
    } catch (e) {
        console.log(e)
        return false;
    }
};