import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';


interface StrategiesListProps {
    strategies: Strategy[];
}

const StrategiesList: React.FC<StrategiesListProps> = ({ strategies }) => {
    const navigate = useNavigate();
    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold">Strategies List</Text>
                <Button colorScheme="green"
                    onClick={() => {
                        navigate('/strategies/create')
                    }}
                >Create Strategy</Button>
            </Box>
            <Table variant="striped" p={'1rem'}>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Coin Pairs</Th>
                        <Th>Active Status</Th>
                        <Th>Stop Loss Percentage</Th>
                        <Th>#Accounts</Th>
                        <Th>Last Modified</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {strategies.map((account) => (
                        <Tr key={account.id}>
                            <Td>{account.id}</Td>
                            <Td>{account.strategy_name}</Td>
                            <Td>
                                {account.coin_pairs && account.coin_pairs.split(',').map((coin, index) => {
                                    return <Text key={index}>{coin}</Text>
                                })}
                                </Td>
                            <Td>{account.is_active ? 'Active' : 'Inactive'}</Td>
                            <Td>{account.stop_loss_percentage}</Td>
                            <Td>{account.num_accounts}</Td>
                            <Td>{new Date(account.updated_at).toLocaleDateString()
                                }</Td>
                            <Td>
                                <Button colorScheme="blue"
                                    onClick={() => {
                                        navigate(`/strategies/${account.id}`)
                                    }}
                                >Edit</Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default StrategiesList;